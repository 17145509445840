<template>
  <div class="phonebox">
    <div class="phonetitle">生产订单</div>
    <div class="phonecx">
      <el-input style="width:50%;margin-right: 10px; " placeholder="请输入物料名称或者客户名称" v-model="name" autocomplete="off"></el-input>
      <el-button type="primary" @click="cx">查询</el-button>
    </div>
    <div class="phoneMain">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="order_date" label="日期" min-width="20%"></el-table-column>

        <el-table-column prop="customer_name" label="客户" min-width="20%"></el-table-column>
        <el-table-column prop="material_name" label="名称" min-width="30%"></el-table-column>
        <el-table-column prop="material" label="材料" min-width="20%"></el-table-column>
        <el-table-column prop="num" label="数量" min-width="18%"></el-table-column>
        <el-table-column prop="unit_price" label="单价" min-width="20%"></el-table-column>
        <!-- <el-table-column prop="total_price" label="总价"></el-table-column> -->

      </el-table>
      <div class="pagbox">
        <el-pagination :pager-count='5' @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="phoneFoot">
      <div class="footbox active">
        <router-link :to="{name:'phoneProductionOrder'}"> 生产订单</router-link>
      </div>
      <div class="footbox ">
        <router-link :to="{name:'phoneSchedule'}"> 生产排程</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'home'}"> 实时看板</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneReport'}"> 设备报表</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneStaffreport'}"> 员工报表</router-link>
      </div>
      <div class="footbox">

        <router-link :to="{name:'phoneconsu'}"> 耗材库存</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrder } from "@/service/api";

export default {
  data () {
    return {
      list: [],
      page: 1,
      name: '',
      limit: 10
    };
  },
  methods: {
    xalert () {
      alert('开发中');
    },
    cx () {
      this.page = 1;
      this.getlist();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getlist();
    },
    getlist () {
      let data = { limit: this.limit, page: this.page, name: this.name };
      console.log(data);
      getOrder(data).then(res => {
        console.log(res);
        res.data.list.forEach(item => {
          item.order_date = item.order_date ? this.dayjs(item.order_date).format('MM-DD') : '无';
          item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('MM-DD') : '无';
        });
        this.list = res.data.list;
        this.total = res.data.total;
      });
    }
  },
  mounted () {
    this.getlist();
  }
};
</script>
<style scoped>
@import './css/index.css';
</style>